import React from 'react'
import {Link} from 'gatsby'

import {Container,Row, Col} from 'reactstrap'

import Layout from '../components/layout'
import Cloud from '../components/cloud'

const IndexPage = () => (
    <Layout title="Homepage">
        <Cloud title="Mit Cloudlift auf dem Weg zu Cloud basierenden Applikationen."/>

        <Container className="bg-white cloud-home">
            <h2 className="text-center">Nutzen Sie die vielen Vorteile der Cloud.</h2>

            <Row>
                <Col md="6" className="item left top">
                    <div className="cloud-item text-center">
                        <i className="fas fa-cloud"></i>
                        <h3>Flexibilität</h3>
                        <p>Cloud-basierte Dienste sind ideal für Unternehmen mit dynamisch wachsenden Anforderungen an Server Ressourcen. Mit der Flexibilität der Cloud Provider erschaffen wir Ihren Applikationen einen Vorteil gegenüber der Konkurrenz.</p>
                        <p>Mit unseren <Link to="/services/">Services</Link> im Bereich <b>Cloud Strategy</b> planen wir Ihre Applikationen mit Hinsicht auf eine hohe Flexibilität.</p>
                    </div>
                </Col>
                <Col md="6" className="item right top">
                    <div className="cloud-item text-center">
                        <i className="fas fa-balance-scale"></i>
                        <h3>Skalierbarkeit</h3>
                        <p>Mit einer wachsenden Anzahl von Usern steigen gleichzeitig auch die <b>Auslastungen</b> der Applikationen. Ob <b>100</b> oder <b>10’000</b> User, von <b>1</b> bis zu <b>1000</b> Servern, die Cloud Provider ermöglichen das automatische erzeugen von neuen Server Instanzen basierend auf ihren gegenwärtigen User zahlen.</p>
                        <p>Wir konfigurieren Ihre Applikationen von Anfang an mit der Möglichkeit, eine <b>dynamische</b> Anpassungen der Server <b>Infrastruktur</b> vorzunehmen.</p>
                    </div>
                </Col>
                <Col md="6" className="item left bottom">
                    <div className="cloud-item text-center">
                        <i className="fas fa-dollar-sign"></i>
                        <h3>Kosteneffizient</h3>
                        <p>Mit einer <b>effizienten</b>, korrekten Anwendung von Cloud Hosting Systemen, erhält man eine Kosteneffiziente Methode, um Applikationen zu betreiben. Das „Pay as You Go“ Modell der Cloud Hosting Anbieter, erlaubt die Kosten Abrechnung basieren auf den verwendeten Server Ressourcen. Damit ersparen Sie große Investitionen von Physikalischen Server.</p>
                        <p>Wir verwenden Cloud Hosting für alle über das Internet erreichbare Applikationen.</p>
                    </div>
                </Col>
                <Col md="6" className="item right bottom">
                    <div className="cloud-item text-center">
                        <i className="fas fa-hdd"></i>
                        <h3>Datenspeicher</h3>
                        <p>Das Speichern von Informationen in der Cloud ermöglicht Ihnen fast <b>unbegrenzte</b> Speicherkapazität.</p>
                        <p>Cloudlift Applikationen werden von Anfang an mit <b>Cloud Storage</b> integriert, somit müssen Sie sich nicht mehr darum kümmern, den Speicherplatz zu verwalten oder Ihre aktuelle Speicherplatzverfügbarkeit zu erhöhen.</p>
                    </div>
                </Col>
            </Row>
        </Container>

    </Layout>
)

export default IndexPage
